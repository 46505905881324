@media all and (min-width: 1200px) {
	.container,
	#our-services-title,
	#our-services,
	#about-us .panel-grid-cell,
	#about-us-2 .panel-grid-cell,
	#testimonials,
	#contact-form,
	#list-accessories,
	#automation-products,
	#automation-descr  {
		width: 1054px;
		margin-left: auto !important;
		margin-right: auto !important;
	}

}

html,
body {
	height: 100%;
	min-height: 100%;
	font-family: "GreyscaleBasic";
	font-size: 18px;
}

main {
	width: 100% !important;
}

a {
    outline-style: none;
    -webkit-transition: all .5s;
    -moz-transition: all .5s;
    -ms-transition: all .5s;
    -o-transition: all .5s;
    transition: all .5s;
    transition-timing-function: ease-out;
    color: rgb(0, 0, 238);

    &:hover {
    	color: rgb(0, 0, 238);
    }
}

@font-face {
  font-family: 'CenturyGothic';
  src: url('../fonts/CenturyGothic.eot?#iefix') format('embedded-opentype'),  url('../fonts/CenturyGothic.woff') format('woff'), url('../fonts/CenturyGothic.ttf')  format('truetype'), url('../fonts/CenturyGothic.svg#CenturyGothic') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Planer';
  src: url('../fonts/Planer.eot?#iefix') format('embedded-opentype'),  url('../fonts/Planer.woff') format('woff'), url('../fonts/Planer.ttf')  format('truetype'), url('../fonts/Planer.svg#Planer') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'GreyscaleBasic';
  src: url('../fonts/GreyscaleBasic.eot?#iefix') format('embedded-opentype'),  url('../fonts/GreyscaleBasic.woff') format('woff'), url('../fonts/GreyscaleBasic.ttf')  format('truetype'), url('../fonts/GreyscaleBasic.svg#GreyscaleBasic') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'GreyscaleBasic-Bold';
  src: url('../fonts/GreyscaleBasic-Bold.eot?#iefix') format('embedded-opentype'),  url('../fonts/GreyscaleBasic-Bold.woff') format('woff'), url('../fonts/GreyscaleBasic-Bold.ttf')  format('truetype'), url('../fonts/GreyscaleBasic-Bold.svg#GreyscaleBasic-Bold') format('svg');
  font-weight: normal;
  font-style: normal;
}





.page-header {
	display: none;
}

#our-services-title {
	margin: 35px 0;

	h3 {
		text-align: center;
		font-size: 71px;
		color: #58656f;
		font-family: "GreyscaleBasic";

		@media all and (max-width: 767px) {
			font-size: 26px;
		}
	}
}

#partner-title {
	background-color: #58656f;
	text-align: center;
	margin-bottom: 80px !important;
	margin-top: 25px;

	h3 {
		font-size: 48px;
		font-family: "GreyscaleBasic-Bold";
		padding: 20px 0;
		color: #ffffff;
		margin-bottom: 0;

		@media all and (max-width: 767px) {
			font-size: 26px;
		}
	}
}

#footer-partners {
	margin-bottom: 40px;

	.sow-image-container {
		text-align: center;

		.so-widget-image {
			display: inline-block !important;

			@media all and (max-width: 767px) {
				max-width: 200px;
			}
		}
	}
}

#our-services {
	.widget-title {
		text-transform: uppercase;
		font-size: 36px;
		font-family: "GreyscaleBasic-Bold";
		color: #006dae;
		margin-bottom: 35px;
		text-align: center;


		@media all and (max-width: 767px) {
			font-size: 20px;
		}
	}

	img {
		max-width: 200px;
		height: auto;
	}

	p {
		font-size: 14px;
		font-family: "GreyscaleBasic";
		color: #58656f;
	}

	h4 {
		text-align: center;
		margin-top: 35px;

		a {
			color: #58656f;
			font-size: 24px;
			font-family: "GreyscaleBasic-Bold";
		}
	}

	#pgc-2-our-services-0 img,
	#pgc-7-our-services-0 img {
		max-width: 150px;
		margin-bottom: 30px;
	}

	#pgc-2-our-services-2 img,
	#pgc-7-our-services-2 img {
		margin-bottom: 37px;
	}


	#pgc-2-our-services-1,
	#pgc-7-our-services-1 {
		padding: 0;

		.textwidget {
			border-left: 5px solid #58656f;
			border-right: 5px solid #58656f;
			padding: 0 60px;
		}

		img {
			max-width: 150px;
		}

		.widget-title {
			margin-bottom: 15px;
		}
	}
}

#about-us,
#about-us-2 {
	background-color: #006dae;
	margin-bottom: 0 !important; 
	padding-bottom: 30px;

}

#about-us {
	h3 {
		text-align: center;
		font-size: 71px;
		padding-top: 55px;
		margin-bottom: 25px;
		color: #ffffff;
		font-family: "GreyscaleBasic";
	}

	p {
		font-family: "GreyscaleBasic";
		font-size: 18px;
		color: #ffffff;
	}
}

#about-us-2 {
	h3 {
		margin-top: 0 !important;
		color: #ffffff;
	}

	p {
		color: #ffffff;

	}
}

#testimonial-title {
	text-align: center;
	margin-top: 50px;

	h3 {
		color: #006dae;
		font-family: "GreyscaleBasic-Bold";
		font-size: 48px;
	}
}

#testimonials {

	p {
		color: #006dae;
		font-family: "GreyscaleBasic";
		font-size: 18px;
	}

	img {
		max-width: 150px;
		height: auto;
	}
}


body.it-services {
	header .metaslider .caption-wrap {
		width: 90%;
		right: 5%;
		border-radius: 0px !important;

		.caption {
			line-height: 1;
			font-size: 60px;
			text-align: center;

			@media all and (max-width: 767px) {
				font-size: 30px;
			}
		}
	}

	main #pl-9 {

		@media all and (min-width: 1024px) {
			width: 1054px !important;
			margin-left: auto !important;
			margin-right: auto !important;
			margin-top: 80px;
		}

		@media (max-width: 780px) {

			#pg-9-1 {
				margin-bottom: 10px;
			}

			#pg-9-0 .panel-grid-cell, #pg-9-1 .panel-grid-cell, #pg-9-2 .panel-grid-cell {
			    float: left;
			    width: 100%;
			}
			.panel-grid .panel-grid-cell-empty {
				margin: 0 !important;
				padding: 0 !important;
			}
		}

		.panel-grid-cell {
			display: table;
		}

		.so-panel {
			background-color: #006dae;
			height: 300px;
			position: relative;
			display: table-cell;
			vertical-align: middle;
			text-align: center;
			padding: 0 25px;

			h3 {
				color: #ffffff;
				font-size: 26px;
				text-transform: uppercase;
			}

			p {
				display: none;
				color: #ffffff;
			}


			&:hover {
				h3 {
					display: none;
				}

				p {
					display: block;
				}
			}
		}
	}
}

body.contact-us {

	#partner-title,
	#footer-partners {
		display: none;
	}

	#contact-form {
		margin-top: 80px;
		margin-bottom: 50px;

		@media all and (max-width: 767px) {
			text-align: center !important;
		}

		h3 {
			text-transform: uppercase;
			font-size: 30px;
			color: #006dae;
		}

		label {
			color: #006dae;
			width: 100%;
			max-width: 400px;
			font-size: 16px;
		}

		input,
		textarea {
			outline: 0;
			border: 1px solid #cdd1d4;
			border-radius: 3px;
			width: 100%;
			max-width: 400px;
		}

		p {
			font-size: 18px;
			color: #45535f;
			margin-bottom: 0;

			strong {
				color: #006dae;
			}
		}

		.wpcf7-submit {
			width: auto;
			background-color: #006dae;
			color: #ffffff;
			font-size: 13px;
			padding: 5px 25px;
		}
	}

}

#point-of-sale {
	
	p {
		font-size: 14px;
		font-family: "GreyscaleBasic";

		strong {
			font-family: "GreyscaleBasic-Bold";
		}
	}
}

#kounta-packages {
	background-color: #0069ad;

	.panel-grid-cell {
		padding: 0 !important;
	}

	.kounta-packages {
		padding: 35px;

		h3 {
			text-align: center;
			color: #ffffff;
			font-size: 40px;
			font-family: "GreyscaleBasic-Bold";
			text-transform: uppercase;
		}

		p {
			font-size: 21px;
			color: #ffffff;
			text-align: center;
			font-family: "GreyscaleBasic";
		}

		.left,
		.right {

			@media all and (min-width: 1200px) {
				width: 50%;
				float: left;
			    padding: 0 35px;
		    }

			h4 {
				text-align: center;
				font-size: 40px;
				font-family: "GreyscaleBasic-Bold";
				color: #F59F00;
			}

			h6 {
				text-align: center;
				font-size: 24px;
				font-family: "GreyscaleBasic-Bold";
				color: #ffffff;
			}

			p {
				text-align: left;
				font-size: 14px;
				font-family: "GreyscaleBasic";
			}
		}

		.left {
			@media all and (min-width: 1200px) {
				border-right: 5px solid #F59F00;
			}
		}
	}
}

#accessories-title {
	margin-top: 50px;

	h3 {
		text-align: center;
		text-transform: uppercase;
		font-size: 40px;
		font-family: "GreyscaleBasic-Bold";
		color: #0069AC;
	}

	p,
	.textwidget {
		text-align: center;
		font-size: 21px;
		font-family: "GreyscaleBasic";
		color: #0069AC;
	}
}


#list-accessories {

	.panel-grid-cell {
		text-align: center;

		p.img {
			min-height: 245px;
		}

		img {
			max-width: 100%;
			height: auto;
			display: inline-block;
		}

		p,
		.column {
			font-size: 18px;
			font-family: "GreyscaleBasic";
			color: #0069AC;

			strong {
				font-size: 24px;
				font-family: "GreyscaleBasic-Bold";
			}
		}
	}
}

#metaslider_container_105 {
	max-height: 750px;
	overflow: hidden;
}

#automation-title {

	h2 {
		font-size: 38px;
		font-family: "CenturyGothic";
		color: #0069AC;
		text-align: center;
		text-transform: uppercase;
		letter-spacing: 10px;

		@media all and (max-width: 479px) {
			font-size: 20px;
			letter-spacing: 3px;
		}

		span {
			color: #000000;
		}
	}
}

#automation-products {

	h4 {
		color: #000000;
		text-align: center;
		font-family: "CenturyGothic";
		letter-spacing: 2px;
		font-size: 16px;
		text-transform: uppercase;
		margin-top: 30px;
		margin-bottom: 50px;

		span {
			color: #0069AC;
		}
	}

	p {
		text-transform: uppercase;
		font-family: "CenturyGothic";
		font-size: 11px;

		&.dbl {

			@media all and (min-width: 768px) {
				-webkit-column-count: 2; /* Chrome, Safari, Opera */
				-moz-column-count: 2; /* Firefox */
				column-count: 2;
			}
		}
	}

	img {
		max-height: 150px;
		width: auto;
	}
}

#automation-descr {
	h2 {
		font-size: 38px;
		font-family: "CenturyGothic";
		color: #0069AC;
		text-align: center;
		text-transform: uppercase;
		letter-spacing: 10px;

		@media all and (max-width: 479px) {
			font-size: 20px;
			letter-spacing: 3px;
		}

		span {
			color: #000000;
		}
	}

	p {
		text-transform: uppercase;
		font-family: "CenturyGothic";
		font-size: 14px;

		@media all and (min-width: 768px) {
			-webkit-column-count: 2; /* Chrome, Safari, Opera */
			-moz-column-count: 2; /* Firefox */
			column-count: 2;
		    -webkit-column-gap: 40px; /* Chrome, Safari, Opera */
		    -moz-column-gap: 40px; /* Firefox */
		    column-gap: 40px;
		}

	}
}