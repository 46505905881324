footer {
    width: 100%;
    padding: 50px 0;
    background-color: #2b2a29;
    float: left;

    @media all and (max-width: 767px) {
        text-align: center;
    }

    #footer {


        p {
            color: #ffffff;
            font-size: 26px;
            margin-bottom: 0;

            @media all and (max-width: 767px) {
                font-size: 18px;
                text-align: center !important;
            }
        }

        .panel-grid-cell .so-panel {
            margin-bottom: 0;
        }
    }

    .menu {
    	padding-left: 0;
    	list-style-type: none;
	    margin: auto;
	    overflow: hidden;
	    width: auto;
        float: right;
	    text-align: center;
	    margin-bottom: 10px;

    	li {
    		float: left;

    		a {
			    font-size: 12px;
                font-family: "CenturyGothic";
			    color: #ffffff;
		        border-right: 1px solid #ffffff;
		        padding: 0 10px;

		        &:hover {
		        	text-decoration: none;
		        	color: #ffffff;
		        }
    		}

    		&:last-child {
    			a {
    				border-right: none;
    			}
			}
    	}
    }

}