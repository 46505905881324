header {

	.top-box {
		margin-top: 15px;

		@media all and (max-width: 767px) {

			.text-left {
				margin-top: 10px;
				margin-bottom: 15px;
			}

			.brand {
				max-width: 200px;
			}

			.header-contact p {
				display: inline-block;
				font-size: 14px;
			}

		}
	}

	.brand {
		display: inline-block;
		text-align: left;
		max-height: 150px;

		img {
			max-width: 100%;
		}
	}


	.navbar-toggle {
		position: absolute;
		top: -100px;
		right: 0;
		margin-top: 0;
	}

	.main-menu {
		padding: 0;
	}

	.header-contact {
		margin-bottom: 15px;

		p {
			color: #58656f;
			font-size: 15px;
			margin-bottom: 0;
			font-family: "CenturyGothic";
		}
	}

	section.widget_sow-slider {
		position: relative;
	}


	.metaslider .caption-wrap {
	    width: auto;
	    bottom: 50px;
	    right: 0;
	    left: auto;
	    background: rgba(0,109,174, .8);
	    border-top-left-radius: 5px;
	    border-bottom-left-radius: 5px;
	    opacity: 1;

	    .caption {
	    	padding: 15px 50px;
		    color: #ffffff;
		    font-size: 32px;
		    font-family: "GreyscaleBasic";
		    line-height: 1;
	    }
	}

	.rslides_nav.prev,
	.rslides_nav.next {
		font-size: 28px !important;
		color: #ffffff !important;
	}

	.navbar-collapse {
		padding: 0;
	}

	.navbar-toggle {
		background-color: #006dae;
	}

	.navbar-toggle .icon-bar {
		background-color: #ffffff;
	}

	.nav-primary {
		float: left;
		padding: 0;
		width: 100%;
		background-color: #58656F;
		z-index: 3;
		position: relative;
		
		@media all and (min-width: 768px) {
			padding-right: 25px;
		}

		ul {
			float: right;

			@media all and (max-width: 767px) {
				width: 100%;
			}

		}

		li {
			float: left;
			position: relative;

			@media all and (min-width: 768px) {
				margin-left: 5px;
			}

			&.active {
				a:before {

					@media all and (min-width: 768px) {
						border-top: 6px solid #006dae;
						content: "";
						position: absolute;
						width: 100%;
						height: 5px;
						top: -10px;
						left: 0;
					}
				}
			}

			a {
				color: #FFF;
				font-size: 14px;
				line-height: 24px;
				padding: 15px;
				background-position: right;
				background-repeat: no-repeat;
				margin: 0;
				text-align: center;
				font-family: "CenturyGothic";

				@media all and (max-width: 767px) {
					font-size: 14px !important;
					padding: 10px !important;
					border-color: rgba(255,255,255, .3) !important;
				}

				&:hover:before {
					@media all and (min-width: 768px) {
						border-top: 6px solid #006dae;
						content: "";
						position: absolute;
						width: 100%;
						height: 5px;
						top: -10px;
						left: 0;
					}
				}

				@media all and (max-width: 1199px) {
					font-size: 15px;
				}

				@media all and (max-width: 991px) {
				    font-size: 15px;
				}
			}

		}

		@media all and (max-width: 767px) {
			padding: 0;

			ul {
				padding-left: 0;

				li {
					width: 100%;

					a {
						display: block;
					    padding: 9.7px 15px;
					    color: #FFF;
					    border-bottom: 1px #666 solid;
					    border-right: 0;
					    font-size: 20px;
					}
				}
			}
		}

	}



	.nav>li>a:focus, .nav>li>a:hover {
		background: transparent;
	}
}
